import React from 'react';
import './proj.css'; // Import CSS file



const parseGroupMembers = (membersString) => {
  const members = membersString.split(',');

  // Remove extra spaces around names and emails
  const formattedMembers = members.map(member => member.trim());

  // Extract names and emails
  const names = formattedMembers.map(member => {
    // Extract name part (before email in parentheses)
    const name = member.split('(')[0].trim();
    return name;
  });

  const emails = formattedMembers.map(member => {
    // Extract email part (inside parentheses)
    const match = member.match(/\(([^)]+)\)/); // Match text inside parentheses
    const email = match ? match[1] : ''; // Extract email or use empty string if not found
    return email.trim();
  });

  // Return an array of objects containing name and email
  return names.map((name, index) => ({ name, email: emails[index] }));
};

const ProjectList = ({ projectData }) => {
    const projectUrl = 'https://project-exhibition.vercel.app/'; // Replace with your project URL
  
    return (
      <div className="project-list">
        <h2>Projects Allotted to Groups</h2>
        {projectData.map(project => (
          <div key={project.sno} className="project-item">
            <div className="group-number">{project['group Number']}</div>
            <div className="project-details">
              <div><strong>Project Allotted:</strong> {project['PROJECT ALOTTED']}</div>
              <div><strong>Group Members:</strong></div>
              <ul className="group-members">
                {parseGroupMembers(project['Group members (At max 4 members in one group)']).map((member, index) => (
                  <li key={index} className="member">
                    <strong>{member.name}</strong> - {member.email}
                  </li>
                ))}
              </ul>
              <div className="project-link">
                <a href={`${projectUrl}${encodeURIComponent(project['PROJECT ALOTTED'])}`} target="_blank" rel="noopener noreferrer">
                  View Project Description
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  

export default ProjectList;
