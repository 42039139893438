import React from 'react';
import ProjectList from './ProjectList'; // Adjust import path as necessary

const App = () => {
  const projectData = [

    {
      "sno": 1,
      "group Number": "group 1",
      "Group members (At max 4 members in one group)": "navya yadav(navya.yadav_cs22@gla.ac.in), himanshi agrawal(himanshi.agrawal_cs22@gla.ac.in), tanu(tanu.gla_cs22@gla.ac.in), vindhya sharma(vindhya.sharma_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  1",
      "": ""
    },
    {
      "sno": 2,
      "group Number": "group 2",
      "Group members (At max 4 members in one group)": "Saksham Singh (saksham.singh_cs22@gla.ac.in), Lakshay Pandey (lakshay.pandey_cs22@gla.ac.in) , Yuvraj Yadav (yuvraj.yadav_cs22@gla.ac.in) , Vipin Yadav (vipin.yadav_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  2",
      "": ""
    },
    {
      "sno": 3,
      "group Number": "group 3",
      "Group members (At max 4 members in one group)": "Vansh sharma(vansh.sharma1_cs22@gla.ac.in),Akash sharma(akash.sharma_cs22@gla.ac.in),Sameer tomar (sameer.tomar_cs22@gmail.com), Love gupta(love.gupta_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  3",
      "": ""
    },
    {
      "sno": 4,
      "group Number": "group 4",
      "Group members (At max 4 members in one group)": "Mandavi Singh(mandavi.singh_cs22@gla.ac.in),Swechchha Nigam(swechchha.nigam_cs22@gla.ac.in),Raunak Verma,Anuradhika Singh(anuradhika.singh_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  4",
      "": ""
    },
    {
      "sno": 5,
      "group Number": "group 5",
      "Group members (At max 4 members in one group)": "Ritesh Kumar Chaudhary (ritesh.chaudhary_cs22@gla.ac.in), Yuvraj Sharma, Rahul Kumar Sahani, Abhishek kumar",
      "PROJECT ALOTTED": "PROJECT  5",
      "": ""
    },
    {
      "sno": 6,
      "group Number": "group 6",
      "Group members (At max 4 members in one group)": "Abhishek Kumar,Ayush Gupta,Satyam Agrawal,Vivek Singh",
      "PROJECT ALOTTED": "PROJECT  6",
      "": "abhishek.kuma_cs22@gla.ac.in , satyam.agrawal_cs22@gla.ac.in"
    },
    {
      "sno": 7,
      "group Number": "group 7",
      "Group members (At max 4 members in one group)": "Vishu Diwakar (vishu.diwakar_cs22@gla.ac.in),Rajat Varshney(rajat.varshney_cs22@gla.ac.in),Mayank Sharma(mayank.sharma3_cs22@gla.ac.in), Vansh Singhal(vansh.singhal_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  7",
      "": ""
    },
    {
      "sno": 8,
      "group Number": "group 8",
      "Group members (At max 4 members in one group)": "Lakshya sharma(lakshya.sharma_cs22@gla.ac.in),Sanskar Khewariya(sanskar.khewariya_cs22@gla.ac.in),Saloni Singh(saloni.singh_cs22@gla.ac.in),Nilisha Rawat(nilisha.rawat_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  8",
      "": ""
    },
    {
      "sno": 9,
      "group Number": "group 9",
      "Group members (At max 4 members in one group)": "Jatin Sharma(Jatin.sharma_cs22@gla.ac.in),    Sumit Rathore( sumit.rathore_cs22@gla.ac.in) ,   Abhay Krishan Nagaich( Abhay.nagaich_cs22@gla.ac.in),   Aman Ali(aman.ali_cs22@gla.ac.in",
      "PROJECT ALOTTED": "PROJECT  9",
      "": "sumit.rathore_cs22@gla.ac.in ,Jatin.sharma_cs22@gla.ac.in. ,Abhay.nagaich_cs22@gla.ac.in"
    },
    {
      "sno": 10,
      "group Number": "group 10",
      "Group members (At max 4 members in one group)": "Shivam Tiwari (shivam.tiwari_cs22@gla.ac.in) , Yuvansh Pratap Singh (yuvansh.singh_cs23@gla.ac.in) , Nikhil Kushwaha (nikhil.kushwah_cs22@gla.ac.in) , Mahesh Dhangar (Mahesh.dhangar_cs23@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  10",
      "": ""
    },
    {
      "sno": 11,
      "group Number": "group 11",
      "Group members (At max 4 members in one group)": "Rishabh Raj Pandey(rishabh.pandey1_cs22@gla.ac.in), Anshuman pandey ( anshyuman.pandey_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  11",
      "": ""
    },
    {
      "sno": 12,
      "group Number": "Group 12",
      "Group members (At max 4 members in one group)": "Tanuj sharma(tanuj.sharma_cs22@gla.ac.in) ,Aman Rajawat, Shiva gautam(shiva.gautam_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  12",
      "": ""
    },
    {
      "sno": 13,
      "group Number": "Group 13",
      "Group members (At max 4 members in one group)": "Gauri Agrawal(gauri.agrawal_cs22@gla.ac.in) ,Shobhit Kumar (shobhit.kumar_cs22@gla.ac.in) ,Pragati Gupta(pragati.gupta_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  13",
      "": ""
    },
    {
      "sno": 14,
      "group Number": "Group 14",
      "Group members (At max 4 members in one group)": "Hardik Arora (hardik.arora_cs.aiml22@gla.ac.in) , Harsh Raj (harsh.raj_cs.aiml22@gla.ac.in) , Pradeep Pratap Singh (pradeep.singh_cs22@gla.ac.in) , Mayank Dixit (Mayank.dixit_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  14",
      "": ""
    },
    {
      "sno": 15,
      "group Number": "Group  15",
      "Group members (At max 4 members in one group)": "Sweety Singh (sweety.singh_cs22@gla.ac.in), Gautam Saraf ,ABHISHEK CHOUDHARY,  Gavendra Pratap Singh(gavendra.singh_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  15",
      "": ""
    },
    {
      "sno": 16,
      "group Number": "Group 16",
      "Group members (At max 4 members in one group)": "Dheeraj Gaur (dheeraj.gaur_cs23@gla.ac.in) , Neelesh Shakya (neelesh.shakya_cs23@gla.ac.in) , Rahul (rahul.gla2_cs23@gla.ac.in) , Divesh Singh (divesh.singh_cs23@gla.ac.in) ....",
      "PROJECT ALOTTED": "PROJECT  16",
      "": ""
    },
    {
      "sno": 17,
      "group Number": "Group 17",
      "Group members (At max 4 members in one group)": "Anay Gupta (anay.gupta_cs22@gla.ac.in) , Abhay Giri (abhay.giri_cs22@gla.ac.in) ,Vibhav pratap Singh (vibhav.singh_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  17",
      "": "abhay.giri_cs22@gla.ac.in"
    },
    {
      "sno": 18,
      "group Number": "Group 18",
      "Group members (At max 4 members in one group)": "moved t different group",
      "PROJECT ALOTTED": "none",
      "": "sonu.kumar_cs22@gla.ac.in"
    },
    {
      "sno": 19,
      "group Number": "Group 19",
      "Group members (At max 4 members in one group)": "Jatin Manglani(jatin.manglani_cs22@gla.ac.in), Prashant Srivastava(prashant.srivastava_cs22@gla.ac.in), Omvir Gyan(omvir.gyan_cs22@gla.ac.in),Shubh Srivastava",
      "PROJECT ALOTTED": "PROJECT  19",
      "": ""
    },
    {
      "sno": 20,
      "group Number": "Group 20",
      "Group members (At max 4 members in one group)": "Ram Lakhan(ram.lakhan_cs22@gla.ac.in), Shubham Maurya(shubham.maurya_cs22@gla.ac.in), Ayush(ayush.gla3_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  20",
      "": ""
    },
    {
      "sno": 21,
      "group Number": "Group 21",
      "Group members (At max 4 members in one group)": "Riya Gupta,Nishu Adhana(nishu.adhana_cs22@gla.ac.in),Pranav pandey(pranav.pandey_cs22@gla.ac.in),Tripti Sharma(tripti.sharma_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  21",
      "": ""
    },
    {
      "sno": 22,
      "group Number": "group 22",
      "Group members (At max 4 members in one group)": "Pinkey Agrawal(pinkey.agrawal_cs22@gla.ac.in), Pankaj Pandey(pankaj.pandey_cs22@gla.ac.in), Yogeshwar Bhardwaj(yogeshwar.bhardwaj_cs22@gla.ac.in), Vishal Sikarwar(Vishal.Sikarwar_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  22",
      "": ""
    },
    {
      "sno": 23,
      "group Number": "Group 23",
      "Group members (At max 4 members in one group)": "Ansh Gaur(ansh.gaur_cs22@gla.ac.in), Lakshya Varshney(lakshya.varshney_cs22@gla.ac.in), Priyank Singh(priyank.singh1_cs22@gla.ac.in), Krishna Singh(krishna.singh3_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  23",
      "": ""
    },
    {
      "sno": 24,
      "group Number": "Group 24",
      "Group members (At max 4 members in one group)": "Raj Srivastava(raj.srivastava1_cs22@gla.ac.in), Siddhi Dubey(siddhi.dubey_cs22@gla.ac.in), Shristy(shristy.gla_cs22@gla.ac.in) ,Ujjawal gupta(ujjawal.gupta_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  24",
      "": ""
    },
    {
      "sno": 25,
      "group Number": "Group 25",
      "Group members (At max 4 members in one group)": "Anmol Agarwal,Abhinav Yadav,Devansh,Bhawna",
      "PROJECT ALOTTED": "PROJECT  25",
      "": ""
    },
    {
      "sno": 26,
      "group Number": "Group 26",
      "Group members (At max 4 members in one group)": "Kartikeya Saxena,Anant Tiwari,Ayush Sambharwal,Hemant Sharma",
      "PROJECT ALOTTED": "PROJECT  26",
      "": ""
    },
    {
      "sno": 27,
      "group Number": "Group 27",
      "Group members (At max 4 members in one group)": "Prashant Solanki(prashant.solanki_cs22@gla.ac.in) , Suraj chaudhary, Danveer Singh",
      "PROJECT ALOTTED": "PROJECT  27",
      "": ""
    },
    {
      "sno": 28,
      "group Number": "Group 28",
      "Group members (At max 4 members in one group)": " Jatin kumar , Arun kumar , Sonu kumar ",
      "PROJECT ALOTTED": "project 28",
      "": ""
    },
    {
      "sno": 29,
      "group Number": "Group 29",
      "Group members (At max 4 members in one group)": "Aditya Sharma , Agrima Gupta (agrima.gupta_cs22@gla.ac.in) , Manvi Gupta (manvi.gupta_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  29",
      "": ""
    },
    {
      "sno": 30,
      "group Number": "Group 30",
      "Group members (At max 4 members in one group)": "Mohammed Raiful Alam  mohammed.alam_cs22@gla.ac.in",
      "PROJECT ALOTTED": "PROJECT  30",
      "": ""
    },
    {
      "sno": 31,
      "group Number": "Group 31",
      "Group members (At max 4 members in one group)": "Lalit kumar(lalit.kumar_cs22@gla.ac.in), Ashish talan(ashish.talan_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  31",
      "": ""
    },
    {
      "sno": 32,
      "group Number": "Group 32",
      "Group members (At max 4 members in one group)": "Nishant Kumar Rautela (nishant.rautela_cs23@gla.ac.in), Nitin Singh Chauhan, Nitin Chaudhary",
      "PROJECT ALOTTED": "PROJECT  32",
      "": ""
    },
    {
      "sno": 33,
      "group Number": "group 33",
      "Group members (At max 4 members in one group)": "Sachin Chaudhary(Sachin.chaudhary_cs22@gla.ac.in), Rajat Bhargava (rajat.bhargava_cs22@gla.ac.in), Dheeraj kumar (dheeraj.kumar_cs22@gla.ac.in), Aman Chahar (aman.chahar_cs22@gla.ac.in)",
      "PROJECT ALOTTED": "PROJECT  33",
      "": ""
    },
    {
      "sno": 34,
      "group Number": "group 34",
      "Group members (At max 4 members in one group)": "Dhananjay Pratap Singh",
      "PROJECT ALOTTED": "PROJECT  34",
      "": ""
    },
    {
      "sno": 35,
      "group Number": "group 35",
      "Group members (At max 4 members in one group)": "saurav kumar,Vivek, Muskaan,krati.            vivek.singh_cs22@gla.ac.in.         ,muskan.pal_cs22@gla.ac.in",
      "PROJECT ALOTTED": "PROJECT 12",
      "": ""
    },
    {
      "sno": 36,
      "group Number": "group 36",
      "Group members (At max 4 members in one group)": "Shashank Singh , Himanshu Singh Rajput ,",
      "PROJECT ALOTTED": "PROJECT 17",
      "": "himanshu.rajput_cs22@gla.ac.in"
    },
    {
      "sno": 37,
      "group Number": "group 37",
      "Group members (At max 4 members in one group)": "Raparthi Vikas",
      "PROJECT ALOTTED": "PROJECT 11",
      "": ""
    },
    {
      "sno": 38,
      "group Number": "group 38",
      "Group members (At max 4 members in one group)": "Sanskar johri(sanskar.johri_cs22@gla.ac.in), Divyanshu shrivastav(divyanshu.srivastava_cs22@gla.ac.in), Maheshwari Raghav Amit(maheshwari.amit_cs22@gla.ac.in )",
      "PROJECT ALOTTED": "PROJECT 28",
      "": ""
    },
    {
      "sno": 39,
      "group Number": "group 39",
      "Group members (At max 4 members in one group)": "kapil dev , Anubhav Yadav",
      "PROJECT ALOTTED": "PROJECT 22",
      "": "kapil.dev_cs22@gla.ac.in"
    }
  ]

  return (
    <div className="App">
      <ProjectList projectData={projectData} />
    </div>
  );
};

export default App;
